
  import { Component, Prop } from 'vue-property-decorator'
  import { Field } from './field'
  import { getObjectValueByPath } from '@/utils/vuetify/helpers'

@Component
  export default class HiddenField extends Field {
  @Prop({ type: Boolean, default: false }) readonly setOnLoad!: boolean
  @Prop({ type: String }) readonly itemValue!: string

  get val () {
    const { value, setOnLoad, itemValue } = this
    if (setOnLoad && value) this.set(value)

    return itemValue ? getObjectValueByPath(value, itemValue) : value
  }
  }
